import React from 'react';
import Styled from 'styled-components';
import Video from '../interfaces/Video';

const Thumbnail = Styled.div<{ videoId: string, size: number }>`
    background-image: url(http://img.youtube.com/vi/${p => p.videoId}/hqdefault.jpg);
    background-color: #111;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    aspect-ratio: 16 / 9;
    width: 100%;
    border-radius: 5px;
`;

interface Props {
    video: Video,
    size?: number,
}

export default function VideoThumbnail(props: Props) {

    const size = props.size || 100;

    return (
        <Thumbnail
            videoId={props.video.id}
            size={size}
        />
    );
}