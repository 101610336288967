import React, { createContext, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Video from './interfaces/Video';
import NotFound from './pages/NotFound';
import Videos from './pages/Videos';
import Watch from './pages/Watch';
import BridgewayLogo from './assets/img/white_logo.png';
import Styled from 'styled-components';
import WindowOrientation from './helpers/WindowOrientation';

const Logo = Styled.img`
	position: fixed;
	left: 20px;
	bottom: 20px;
	z-index: 1;
	opacity: .5;
	pointer-events: none;
`;
const AppContainer = Styled.div`
	height: 100vh;
	width: 100vw;
	overflow-y: auto;
	position: relative;
	padding: 30px;
	box-sizing: border-box;
`;
const Debug = Styled.div`
	font-family: Courier New;
	position: fixed;
	bottom :5px;
	right: 5px;
	padding: 5px;
	z-index: 5000;
	background-color: rgba(0, 0, 0, .5);
`;

interface IAppContext {
	devMode: boolean,
	videoList: Video[],
	isMobile: boolean,
	windowSize: {
		width: number,
		height: number,
	},
	orientation: WindowOrientation,
}


const AppContext = createContext<IAppContext>({
	devMode: false,
	videoList: [],
	isMobile: false,
	windowSize: {
		width: -1,
		height: -1,
	},
	orientation: WindowOrientation.LANDSCAPE,
});

function App() {

	const [ windowWidth, setWindowWidth ] = useState(window.innerWidth);
	const [ windowHeight, setWindowHeight ] = useState(window.innerHeight);
	const [ windowOrientation, setWindowOrientation ] = useState( (windowHeight > windowWidth ? WindowOrientation.PORTRAIT : WindowOrientation.LANDSCAPE) );
	const [ isMobile, setIsMobile ] = useState( (windowWidth <= 600 || windowHeight <= 600) );
	const [ devMode, setDevMode ] = useState<boolean>(false);

	useEffect(() => {
		const listener = () => {
			console.log("Caught Window Resize Event");
			setWindowWidth(window.innerWidth);
			setWindowHeight(window.innerHeight);
			setWindowOrientation( (window.innerHeight > window.innerWidth ? WindowOrientation.PORTRAIT : WindowOrientation.LANDSCAPE) );
			setIsMobile( (window.innerWidth <= 600 || window.innerHeight <= 600) );
		};
		window.addEventListener('resize', listener);

		return () => {
			window.removeEventListener('resize', listener);
		};
	}, [ setWindowWidth, setWindowHeight, setWindowOrientation ]);

    const videoList: Video[] = [
		{
			title: "Turning it On and Off",
			id: "bUyjfeH1Zqc",
			description: "Learn how to turn the meeting solution on and off at the push of a button."
		},
		{
            title: "Joining & Creating a Meeting",
            id: "2mJBUaellFQ",
			description: "Learn how to join and create meetings on the fly."
        },
		{
            title: "Meeting Controls",
            id: "M7hmjmvwiho",
			description: "Learn how to control the camera and your microphone during a meeting."
        },
        {
            title: "Sharing Your Screen",
            id: "zN0ruL6P2xc",
			description: "Learn how to share your Laptop screen in the meeting."
        },
    ];

	return (
		<AppContainer>
			<AppContext.Provider
				value={{
					videoList,
					isMobile: isMobile,
					windowSize: {
						width: windowWidth,
						height: windowHeight,
					},
					orientation: windowOrientation,
					devMode,
				}}
			>
				<Routes>
					<Route path="/" element={<Videos />} />
					{videoList.map((video, ind) => (
						<Route path={`/watch/${video.id}`} key={ind} element={<Watch video={video} />} />
					))}
					<Route path="*" element={<NotFound />} />
				</Routes>
				<Logo src={BridgewayLogo} height={80} />
			</AppContext.Provider>

			{devMode && <Debug>
				Window Size: {windowWidth}x{windowHeight}
				<br/>
				Window Orientation: {windowOrientation}
				<br/>
				Is Mobile: {(isMobile ? "Yes" : "No")}
			</Debug>}
		</AppContainer>
	);
}

export default App;
export { AppContext };