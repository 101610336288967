import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Styled from 'styled-components';
import { AppContext } from '../App';
import VideoThumbnail from '../components/VideoThumbnail';

const VideoTile = Styled(Link)`
	display: inline-block;
	padding: 15px;
	border-radius: 5px;
	background-color: transparent;
	transition: background-color linear .15s;
	text-decoration: none;

	width: 80%;
	margin: 10px;
	max-width: 300px;


	&:hover {
		background-color: rgba(255, 255, 255, .15);
		cursor: pointer;
	}

	.title {
		color: white;
		text-decoration: none !important;
		text-align: center;
		padding: 10px;
		font-weight: bold;
		font-size: 15pt;
	}
	.description {
		color: white;
		
	}
`;
const Container = Styled.div<{ isMobile: boolean }>`
	${p => !p.isMobile && `
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50% , -50%);
		max-width: 1200px;
	`}
	text-align: center;
	padding: 10px;
	z-index: 5;
	position: relative;
`;
const PageTitle = Styled.div`
	font-size: 30pt;
	.subtitle {
		font-size: 13pt;
	}
	margin-bottom: 20px;
`;

export default function Videos() {

	const ctx = useContext(AppContext);

	return (
		<Container isMobile={ctx.isMobile}>
			<PageTitle>
				Logitech Meeting Room Guides
				<div className="subtitle">
					<i>Stuck? Watch a video below to find out more.</i>
				</div>
			</PageTitle>
			{ctx.videoList.map( (video, ind) => (
				<VideoTile to={`/watch/${video.id}`}>
					<div className="title">
						{video.title}
					</div>
					<VideoThumbnail video={video} size={(ctx.isMobile ? (((ctx.windowSize.width-100) * .8)) : 200)}/>
					<div className='description'>
						{video.description}
					</div>
				</VideoTile>
			))}
		</Container>
	);
}