import React from 'react';
import Styled from 'styled-components';
import Video from '../interfaces/Video';

const Frame = Styled.iframe<{ width: string, height?: string }>`
    border: none;
    aspect-ratio: 16 / 9;
    width: ${p => p.width};
    height: ${p => p.height};
`;

interface Props {
    video: Video,
    width?: string,
    height?: string,
}

export default function VideoEmbed(props: Props) {
    const width = props.width || "70%";
    return (
        <Frame
            src={`https://www.youtube.com/embed/${props.video.id}`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            width={width}
            height={props.height}
        />
    );
}