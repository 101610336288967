import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import VideoEmbed from '../components/VideoEmbed';
import Styled from 'styled-components';
import Video from '../interfaces/Video';
import BackArrow from '../components/BackArrow';
import { AppContext } from '../App';
import WindowOrientation from '../helpers/WindowOrientation';

const Container = Styled.div<{ isMobile: boolean, orientation: WindowOrientation }>`
	${p => !p.isMobile && `
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 90%;
	`}
	${p => p.isMobile && p.orientation === WindowOrientation.LANDSCAPE && `
		height: 100%;
		position: relative;
	`}
	${p => p.isMobile && p.orientation === WindowOrientation.PORTRAIT && `
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	`}
	
	text-align: center;

	.videoTitle {
		font-size: 16pt;
		margin-bottom: 10px;
	}
	z-index: 50;
`;

const BackButton = Styled(Link)<{ isMobile: boolean }>`
	background-color: transparent;
	padding: 10px;
	border-radius: 100%;
	display: inline-block;
	
	width: 40px;
	height: 40px;
	text-align: center;
	line-height: 60px;
	transition: background-color linear .15s;

	position: relative;
	z-index: 100;

	${p => !p.isMobile && `
		margin: 10px;
	`}

	${p => p.isMobile && `
		position: fixed;
		left: 5px;
		top: 5px;
	`}

	&:hover {
		background-color: rgba(255, 255, 255, .25);
		cursor: pointer;
	}
`;

interface Props {
	video: Video,
}

export default function Watch(props: Props) {

	const ctx = useContext(AppContext);

	let videoHeight = "initial";

	if (ctx.isMobile) {
		if (ctx.windowSize.width > ctx.windowSize.height) {
			videoHeight = "85%";
		}
	}

	return (
		<>
			<BackButton to="/" isMobile={ctx.isMobile}>
				<BackArrow fill={"white"} />
			</BackButton>
			<Container isMobile={ctx.isMobile} orientation={ctx.orientation}>
				<div className="videoTitle">{props.video.title}</div>
				<VideoEmbed video={props.video} width={(ctx.isMobile ? "initial" : "70%")} height={videoHeight} />
			</Container>
		</>
	)
}